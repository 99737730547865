import { ProviderService } from 'shared/models/providerService.model';
import { DateFormat } from 'shared/utils/dateFormat.util';
import { ISupplierSignup } from 'shared/models/supplier/supplierSignup.model';
import Loader from 'shared/utils/loaderHelper.util';
import { EFormView } from 'enums/supplierSignUp.enum';
import { AccountApiService } from 'shared/services/api/accountApi.service';
import { IUserInfo } from 'models/auth/userInfo.model';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import Notificator from 'shared/services/notificator.service';
import { SupplierApiService } from 'services/api/supplierApi.service';
import { ISupplierRegistrationFromFormData } from 'models/supplier/supplierRegistrationFromFormData.model';

export class SupplierSignUpModalService extends ProviderService{
  protected static readonly serviceName = 'supplierSignUpModalService';

  public resendEmailTime = ref(0);
  public formView = ref(EFormView.SignUp);

  public formattedResendEmailTime = computed(() => {
    const duration = DateFormat.intervalToDuration(this.resendEmailTime.value * 1000);
    return `${duration.minutes}:${duration.seconds < 10 ? `0${duration.seconds}` : duration.seconds}`;
  });

  public loadingCreateUser = Loader.getReactiveInstance();
  public loadingCreateSupplier = Loader.getReactiveInstance();
  public loadingResendEmail = Loader.getReactiveInstance();

  private initialResendEmailTime = 120;
  private timerIntervalResendEmail: ReturnType<typeof setInterval> | null = null;
  private userInfo: IUserInfo | undefined;

  public getUserEmail(): string | undefined {
    return this.userInfo?.email;
  }

  public startResendEmailTimer(): void {
    this.clearResendEmail();
    this.resendEmailTime.value = this.initialResendEmailTime;
    this.timerIntervalResendEmail = setInterval(() => this.decreaseResendEmailTime(), 1000);
  }

  public decreaseResendEmailTime(): void {
    if (!this.resendEmailTime.value) {
      this.clearResendEmail();
    } else {
      this.resendEmailTime.value--;
    }
  }

  public clearResendEmail(): void {
    if (this.timerIntervalResendEmail) {
      clearInterval(this.timerIntervalResendEmail);
    }
    this.resendEmailTime.value = 0;
    this.timerIntervalResendEmail = null;
  }

  public async createUser(supplierData: ISupplierSignup): Promise<void> {
    this.loadingCreateUser.activate();

    try {
      this.userInfo = (await AccountApiService.signupSupplier(supplierData)) as unknown as IUserInfo;
      this.changeFormView(EFormView.CompanyInfo);
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        SupplierSignUpModalService.getServiceName(),
        undefined,
        {
          extra: {
            supplierData,
          },
        },
      );
      Notificator.showDetachedNotification('Возникла ошибка при попытке регистрации, попробуйте, пожалуйста, ещё раз');
    } finally {
      this.loadingCreateUser.deactivate();
    }
  }

  public async createSupplier(data: Omit<ISupplierRegistrationFromFormData, 'userSub'>): Promise<void> {
    if (!this.userInfo) {
      this.changeFormView(EFormView.SignUp);
      return;
    }

    this.loadingCreateSupplier.activate();

    try {
      await SupplierApiService.createSupplierFromRegistrationForm({
        ...data,
        userSub: this.userInfo?.sub,
      });

      this.changeFormView(EFormView.Confirm);
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        SupplierSignUpModalService.getServiceName(),
        undefined,
        {
          extra: {
            createSupplierData: data,
            userInfo: this.userInfo,
          },
        },
      );
      Notificator.showDetachedNotification('Ошибка при сохранении информации о компании');
    } finally {
      this.loadingCreateSupplier.deactivate;
    }
  }

  public async resendEmail(): Promise<void> {
    if (!this.userInfo?.email) {
      this.changeFormView(EFormView.SignUp);
      return;
    }

    this.loadingResendEmail.activate();

    try {
      await AccountApiService.resendEmail(this.userInfo.email);
      this.startResendEmailTimer();
    } catch (error) {
      if (error.data?.statusCode === 400) {
        Notificator.showDetachedNotification('E-mail уже был подтвержден.');
      } else {
        Notificator.showDetachedNotification('Произошла ошибка при повторной отправке ссылки');
      }
    } finally {
      this.loadingResendEmail.deactivate();
    }
  }

  public changeFormView(formView: EFormView): void {
    this.formView.value = formView;
  }

  public clear(): void {
    this.clearResendEmail();
    this.userInfo = undefined;
    this.clearLoaders();
    this.changeFormView(EFormView.SignUp);
  }

  private clearLoaders(): void {
    [
      this.loadingResendEmail,
      this.loadingCreateSupplier,
      this.loadingCreateUser,
    ].forEach((loader) => loader.deactivate());
  }
}
