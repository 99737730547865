import { EFormView } from 'enums/supplierSignUp.enum';
import SupplierSignUpPersonalFormView from 'components/modals/supplier-sign-up/SupplierSignUpPersonalFormView.vue';
import SupplierSignUpConfirmView from 'components/modals/supplier-sign-up/SupplierSignUpConfirmView.vue';
import SupplierSignUpNoMessageView from 'components/modals/supplier-sign-up/SupplierSignUpNoMessageView.vue';
import SupplierSignUpCompanyInfoFormView from 'components/modals/supplier-sign-up/SupplierSignUpCompanyInfoFormView.vue';

export const supplierSignUpViewMap = new Map<EFormView, Component>([
  [EFormView.SignUp, SupplierSignUpPersonalFormView],
  [EFormView.Confirm, SupplierSignUpConfirmView],
  [EFormView.NoMessage, SupplierSignUpNoMessageView],
  [EFormView.CompanyInfo, SupplierSignUpCompanyInfoFormView],
]);
