<template>
  <div class="mm-supplier-sign-up-no-message">
    <ul class="mm-supplier-sign-up-no-message__list">
      <li class="mm-supplier-sign-up-no-message__item">
        Проверьте, что вы ввели правильный электронный адрес
      </li>
      <li class="mm-supplier-sign-up-no-message__item">
        Иногда сообщение приходит не сразу, подождите несколько минут и попробуйте получить код еще раз
      </li>
      <li class="mm-supplier-sign-up-no-message__item">
        Пожалуйста, проверьте, достаточно ли свободного места в почтовом ящике
      </li>
      <li class="mm-supplier-sign-up-no-message__item">
        Если авторизоваться не получилось, обратитесь в службу поддержки по адресу <a
          href="mailto:support@maksmart.ru"
        >support@maksmart.ru</a>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-supplier-sign-up-no-message {
  &__list {
    margin: 0;
    padding: 0 0 0 20px;
    list-style: none;
    max-width: 450px;
  }

  &__item {
    position: relative;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $supplier-sign-up-no-msg-c;

    a {
      text-decoration: none;
      color: $supplier-sign-up-list-disc-c;
    }

    &::after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      background-color: $supplier-sign-up-list-disc-c;
      border-radius: 50%;
      position: absolute;
      left: -20px;
      top: 5px;
    }

    &:nth-child(n + 2) {
      margin-top: 32px;
    }
  }
}
</style>
