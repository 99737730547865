<template>
  <div class="mm-supplier-sign-up-company-info-form-view">
    <div class="mm-supplier-sign-up-company-info-form-view__form">
      <TextField
        v-model="innField.value.value"
        icon-disabled
        label="ИНН"
        class="mm-supplier-sign-up-company-info-form-view__field"
        :max-length="12"
        :mask="INPUT_MASK.ONLY_NUMBERS"
        :validation-field="innField"
      />
      <TextField
        v-model="nameField.value.value"
        icon-disabled
        :disabled="disabledNameField.value"
        label="Наименование"
        class="mm-supplier-sign-up-company-info-form-view__field"
        :max-length="1024"
        :validation-field="nameField"
      />
      <TextField
        v-model="websiteField.value.value"
        icon-disabled
        label="Сайт компании (если есть)"
        class="mm-supplier-sign-up-company-info-form-view__field"
        :max-length="128"
        :validation-field="websiteField"
      />
    </div>

    <button
      class="btn btn-primary mm-supplier-sign-up-company-info-form-view__button"
      :disabled="disabledSubmitButton"
      @click="onSubmit"
    >
      Сохранить
    </button>

    <SupplierSignUpDocumentsFooter
      class="mm-supplier-sign-up-company-info-form-view__footer"
      action-text="Сохранить"
    />
  </div>
</template>

<script lang="ts" setup>
import TextField from 'shared/components/TextField.vue';
import SupplierSignUpDocumentsFooter from './SupplierSignUpDocumentsFooter.vue';
import { Validators } from 'shared/utils/validators.util';
import { useField, useForm, useIsFormValid } from 'vee-validate';
import { INPUT_MASK } from 'shared/constants/inputMask.const';
import { EValidatorsMessages } from 'shared/enums/validators.enum';
import { debounce } from 'lodash-es';
import Loader from 'shared/utils/loaderHelper.util';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import Notificator from 'shared/services/notificator.service';
import DadataApiService from 'services/api/dadata.service';
import { SupplierSignUpModalService } from 'services/supplier/supplierSignUpModal.service';
import { WatchSubscription } from 'shared/utils/watchSubscription';
import useSSRUnsubscribeWatch from 'shared/composables/useSSRUnsubscribeWatch';

const supplierSignUpModalService = inject<SupplierSignUpModalService>(SupplierSignUpModalService.getServiceName());
const watchSubscription = new WatchSubscription();

enum EFormField {
  Inn = 'inn',
  Name = 'name',
  Website = 'website',
}

interface IForm {
  [EFormField.Inn]: string;
  [EFormField.Name]: string
  [EFormField.Website]: string;
}

const validationSchema = {
  [EFormField.Inn]: [Validators.required(), Validators.onlyNumbers(), Validators.inn(EValidatorsMessages.InnSupplierSignUp), Validators.neededLengths([10, 12])],
  [EFormField.Name]: [Validators.required()],
  [EFormField.Website]: [Validators.url()],
};

const form = useForm<IForm>({
  validationSchema,
});

const isFormValid = useIsFormValid();

const innField = useField<string>(EFormField.Inn);
const nameField = useField<string>(EFormField.Name);
const websiteField = useField<string>(EFormField.Website);

const debounceSearchByInn = debounce(searchByInn, 300);

const loadingSearch = Loader.getReactiveInstance();
const disabledNameField = Loader.getReactiveInstance();

const disabledSubmitButton = computed(
  () => supplierSignUpModalService?.loadingCreateSupplier.value
    || loadingSearch.value
    || !isFormValid.value,
);

async function searchByInn(): Promise<void> {
  loadingSearch.activate();

  try {
    const companies = await DadataApiService.getCompaniesByInn(innField.value.value);
    if (!companies.length) {
      return;
    }

    const companyName = companies[0].nameFullWithOpf;
    if (companyName) {
      nameField.setValue(companyName);
      disabledNameField.activate();
    } else {
      disabledNameField.deactivate();
    }
  } catch (error) {
    clientSentry.captureComponentException(
      error,
      'SupplierSignUpCompanyInfoView',
      {
        extra: {
          inn: innField.value.value,
        },
      },
    );
    Notificator.showDetachedNotification('Ошибка поиска информации о компании по ИНН');
  } finally {
    loadingSearch.deactivate();
  }
}

async function onSubmit(): Promise<void> {
  await form.validate();

  if (!isFormValid.value) {
    return;
  }

  await supplierSignUpModalService?.createSupplier({
    inn: innField.value.value,
    name: nameField.value.value,
    supplierWebPage: websiteField.value.value,
  });
}

watchSubscription.add(
  watch(
    () => innField.value.value,
    async (newInn, oldInn) => {
      disabledNameField.deactivate();
      nameField.setValue('');

      if ((!oldInn && !newInn)
        || (newInn.length < 10 || newInn.length > 12)
      ) {
        return;
      }

      await debounceSearchByInn();
    },
  ),
);

useSSRUnsubscribeWatch(watchSubscription);
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-supplier-sign-up-company-info-form-view {
  &__field {
    margin: 0;
    padding: 0;

    &:not(&:first-child) {
      margin-top: 12px;
    }
  }

  &__button {
    width: 100%;
    margin-top: 32px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: $white;
    padding: 10px 28px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;

    &:disabled {
      background-color: $btn-primary-disabled-bg;
      color: $btn-primary-disabled-c;
    }
  }
}
</style>
