<template>
  <Modal
    :model-value="modelValue"
    :title-text="titleText"
    :z-index="10"
    ssr
    hide-cancel-button
    hide-ok-button
    hide-footer
    header-class="mm-supplier-sign-up__header"
    title-class="mm-supplier-sign-up__title"
    classes="modal fixed-bottom-modal bottom-0"
    @close="onClose"
    @update:model-value="$emit('update:modelValue', $event)"
  >
    <component
      :is="currentView"
      @login="$emit('login')"
    />
  </Modal>
</template>

<script setup lang="ts">
import Modal from 'shared/components/modals/Modal.vue';
import { EFormView } from 'enums/supplierSignUp.enum';
import { SupplierSignUpModalService } from 'services/supplier/supplierSignUpModal.service';
import { supplierSignUpViewMap } from 'constants/supplier/supplierSignupViewMap.const';

defineProps<{
  modelValue: boolean;
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'login'): void;
}>();

const supplierSignUpModalService = new SupplierSignUpModalService();
provide(SupplierSignUpModalService.getServiceName(), supplierSignUpModalService);

const modalTitleMap = new Map<EFormView, string>([
  [EFormView.SignUp, 'Регистрация'],
  [EFormView.Confirm, 'Проверьте почту'],
  [EFormView.NoMessage, 'Не приходит сообщение'],
  [EFormView.CompanyInfo, 'Укажите данные о компании'],
]);

const currentView = computed(
  () => supplierSignUpViewMap.get(supplierSignUpModalService.formView.value),
);

const titleText = computed(
  () => modalTitleMap.get(supplierSignUpModalService.formView.value),
);

function closeModal(): void {
  emits('update:modelValue', false);
}

function onClose(): void {
  if ([EFormView.NoMessage, EFormView.CompanyInfo].includes(supplierSignUpModalService.formView.value)) {
    return supplierSignUpModalService.changeFormView(EFormView.Confirm);
  }

  closeModal();
}

onUnmounted(() => supplierSignUpModalService.clear());
</script>

<style scoped lang="scss">
@import 'styles/base/common/variables';

:deep(.modal) {
  .modal-header {
    .btn {
      padding: 8px 8px 8px 16px;
    }
  }
}

@media only screen and (max-width: 1279px) {
  :deep(.modal) {
    .modal-header {
      .btn {
        padding-left: 8px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  :deep(.modal) {
    .modal-header {
      margin-bottom: 12px;

      .btn {
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width: 530px) {
  .mm-supplier-sign-up__hint {
    br {
      display: none;
    }
  }
}
</style>
