<template>
  <div class="mm-supplier-sign-up__confirm-view">
    На ваш электронный адрес {{ supplierSignUpModalService?.getUserEmail() }} <br>должна прийти ссылка для подтверждения.

    <ul class="mm-supplier-sign-up__confirm-list">
      <li class="mm-supplier-sign-up__confirm-item">
        Не пришло подтверждение,
        <span
          v-if="!supplierSignUpModalService?.resendEmailTime.value"
          @click="onClickResendEmail"
        >отправить повторно</span>
        <template v-else>
          отправить повторно можно через {{ supplierSignUpModalService.formattedResendEmailTime.value }}
        </template>
      </li>

      <li class="mm-supplier-sign-up__confirm-item">
        Что делать, если не <span @click="supplierSignUpModalService?.changeFormView(EFormView.NoMessage)">приходит письмо</span>
      </li>

      <li class="mm-supplier-sign-up__confirm-item">
        Ошиблись адресом? <span @click="supplierSignUpModalService?.clear()">Изменить почту</span>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { EFormView } from 'enums/supplierSignUp.enum';
import { SupplierSignUpModalService } from 'services/supplier/supplierSignUpModal.service';

const supplierSignUpModalService = inject<SupplierSignUpModalService>(SupplierSignUpModalService.getServiceName());

async function onClickResendEmail(): Promise<void> {
  await supplierSignUpModalService?.resendEmail();
}

onMounted(() => {
  if (!supplierSignUpModalService?.resendEmailTime.value) {
    supplierSignUpModalService?.startResendEmailTimer();
  }
});
</script>

<style scoped lang="scss">
@import 'styles/base/common/variables';

.mm-supplier-sign-up {
  &__confirm-list {
    padding: 0;
    margin: 32px 0 0;
    list-style: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $supplier-sign-up-hint-c;
  }

  &__confirm-item {
    &:nth-child(n + 2) {
      margin-top: 20px;
    }

    span {
      color: $supplier-sign-up-link-c;
      cursor: pointer;
    }
  }
}
</style>
