import ModalManager from 'shared/services/modalManager.service';
import SupplierSignUpModal from 'components/modals/supplier-sign-up/SupplierSignUpModal.vue';
import { IUseSupplierRegistration } from 'models/composables/useSupplierRegistration.model';
import { useAuthFnHelper } from 'composables/useAuthHelper';

export function useSupplierRegistration(): IUseSupplierRegistration {
  const modalManager = process.client ? inject<ModalManager>(ModalManager.getServiceName()) : undefined;

  const { showLoginModal } = useAuthFnHelper();

  function onLogin(): void {
    showLoginModal();
  }

  function onSupplierRegistration(): void {
    const modalName = 'SupplierRegistrationModal';
    modalManager
      .show({
        component: SupplierSignUpModal,
        bind: {
          name: modalName,
        },
        on: {
          login() {
            modalManager.hide(modalName);
            onLogin();
          },
        },
      });
  }

  return {
    onSupplierRegistration,
  };
}
